import { Link } from 'gatsby';
import Layout from "../../../src/components/layout";
import Meta from "../../../src/components/meta";
import React from 'react';
import { MDXTag } from '@mdx-js/tag';
export default {
  Link,
  Layout,
  Meta,
  React,
  MDXTag
};